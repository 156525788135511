<template>
  <div class="question">
    <div class="resolve">
      <div style="width:120px;float: left;font-size: 16px;cursor: pointer;" :class="{active:solveShow===''}" @click="changeClass('')">全部</div>
      <div style="width:120px;float: left;font-size: 16px;cursor: pointer;" :class="{active:solveShow===0}" @click="changeClass(0)">未解决</div>
      <div style="width:120px;float: left;font-size: 16px;cursor: pointer;" :class="{active:solveShow===1}" @click="changeClass(1)">已解决</div>
      <el-button style="float: right;margin: 0 20px" type="primary" size="medium" round @click="askQuestionShow()">提问
      </el-button>
    </div>

    <div v-if="questionList.length>0" class="questionListContent">
      <section v-for="(item,index) in questionList" :key="index" class="questionEvery">
        <div class="title">
          <el-tooltip class="item" effect="dark" :content="item.title" placement="top" :disabled="!showTip">
            <span class="title-c" style="color:#333333;font-weight:600;font-size: 20px;cursor: pointer;" @mouseover="textRange" @click="goToDetail(item.id)"> {{ item.title === null ? '暂无标题' : item.title }}</span>
          </el-tooltip>
          <header>
            <span style="color: #333333">{{ item.isAnonymous == 0 ? item.studentName : '匿名提问' }}</span>
            <span>{{
              questionLabel[item.label].label
            }}</span>
          </header>
        </div>
        <div class="time">{{ item.createTime }}</div>
        <div class="questionContent">
          <!--              <p>-->
          <!--                &lt;!&ndash;            <span >...查看全文</span>&ndash;&gt;-->
          <!--                {{ item.content }}-->
          <!--              </p>-->
          <!--          <div class="readTheFullArticle" @click="goToDetail(item.id)">阅读全文</div>-->
          {{ item.content }}
        </div>
        <div class="mainBody-image">
          <div v-for="(every,index) in item.pictures" :key="index" style="margin: 0 10px 10px 0">
            <el-image :src="every" style="width: 214px;height: 138px" fit="cover" @click="showBigPicture(every)">
              <div slot="error" class="image-slot">
                <!--                    错误的占位符-->
              </div>
            </el-image>
          </div>
        </div>
        <div class="answer-c">
          <div style="font-size: 16px;color: #4392fd;margin: 10px 0">最新回复</div>
          <div v-if="item.replyList.length>0">
            <div v-for="(every,replyIndex) in item.replyList" :key="replyIndex" style="display: flex;border-top: 1px solid #E7ECF2">
              <div v-if="every.type===0" class="answer-name" style="color: #07479D;">{{
                every.replyName === null || every.replyName === '' ? '学生' : every.replyName
              }}回复：
              </div>
              <div v-if="every.type===1" class="answer-name" style="color: #894306">
                老师回复：
              </div>
              <p>{{ every.content }}</p>
            </div>
          </div>
          <div v-else>
            <div style="margin: 20px;font-size: 14px;color: #666666">暂无回复哦~</div>
          </div>
        </div>
        <div style="margin-top: 20px">
          <template>
            <el-radio-group v-model="item.isSolve" @change="changeSolve(item.isSolve,item.id)">
              <el-radio :label="0">未解决</el-radio>
              <el-radio :label="1">已解决</el-radio>
            </el-radio-group>
          </template>
        </div>

      </section>

      <div v-if="questionList.length>0" class="changePage">
        <el-pagination
          background
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-empty v-if="questionList.length===0" description="暂无数据" style="padding: 160px 0" :image="require('@/assets/images/empty.png')" />

    <!-- 提问-->
    <el-dialog
      title="发表提问"
      :visible.sync="askQuestionVisible"
      width="540px"
      :close-on-click-modal="false"
      :before-close="askQuestionClose"
    >
      <div class="text-left">选择标签：
        <el-select v-model="selectedLabel" placeholder="请选择">
          <el-option
            v-for="item in questionLabel"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
        <span style="float:right;">
          <el-checkbox v-model="checked">匿名</el-checkbox>
        </span>
      </div>
      <div class="text-left title">
        <span style="line-height: 40px">输入标题：</span>
        <span>
          <el-input
            v-model="submitTitle"
            placeholder="请输入标题"
            maxlength="30"
            :show-word-limit="true"
            clearable
          />
        </span>
      </div>
      <div style="text-align: left">
        <Editor v-model="askTextarea" :height="250" style="width: 100%" :data="{type:6}" :value="askTextarea" />
      </div>
      <div class="send">
        <el-button type="primary" round @click="submitQuestion">发布</el-button>
      </div>
    </el-dialog>
    <!--    预览大图-->
    <el-dialog :visible.sync="showPicture">
      <img width="100%" :src="currentPic">
    </el-dialog>

    <el-dialog
      center
      title="回复信息"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div class="questionMsg">
        回复信息
      </div>
      <div>
        <el-input
          v-model="textarea"
          type="textarea"
          :rows="7"
          maxlength="500"
          :show-word-limit="true"
          placeholder="请输入内容"
        />
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button round @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" round @click="replyToSave">提交</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getMyQuestionList, subQuestion, updateIsSolve, saveReply } from '../../../api/answer/index'
import { mapState } from 'vuex'
import Editor from '../../../components/Editor'

export default {
  name: 'AskQuestion',
  components: {
    Editor
  },
  created() {
    this.onLoad()
  },
  computed: {
    ...mapState({
      questionLabel: state => state.dict.dictTree.question_label['arr']
    })
  },
  data() {
    return {
      solveShow: '',
      currentPage: 1,
      pageSize: 3,
      total: 0,
      questionList: [],
      currentProblemId: '',
      selectedLabel: '',
      checked: true,
      submitTitle: '',
      askTextarea: '',

      textarea: '',
      dialogVisible: false,
      askQuestionVisible: false,
      options: [{
        value: 0,
        label: '未解决'
      }, {
        value: 1,
        label: '已解决'
      }],
      showTip: false,
      pictures: [
        'https://teaching-management.nos-eastchina1.126.net/class/paperscore/db1435c2f54a4564ad53be56dbf0094a-水瓶.png',
        'https://teaching-management.nos-eastchina1.126.net/class/paperscore/db1435c2f54a4564ad53be56dbf0094a-水瓶.png',
        'https://teaching-management.nos-eastchina1.126.net/class/paperscore/db1435c2f54a4564ad53be56dbf0094a-水瓶.png'
      ],
      // 展示大图的弹框状态
      showPicture: false,
      // 当前展示的大图地址
      currentPic: ''
    }
  },
  methods: {
    onLoad() {
      this.getQuestionList()
    },
    getQuestionList() {
      const obj = {
        isSolve: this.solveShow,
        pageSize: this.pageSize,
        pageNum: this.currentPage
      }
      getMyQuestionList(obj).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.questionList = res.rows
          this.total = res.total
        }
      })
    },
    // 更改选择值
    changeSolve(val, id) {
      console.log(val)
      console.log(id)
      updateIsSolve({ id: id, isSolve: val }).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.$message.success('修改成功')
          this.onLoad()
        }
      })
    },
    // 更改标签
    changeClass(val) {
      this.solveShow = val
      this.onLoad()
    },
    goToDetail(data) {
      this.$router.push({
        path: '/answerQuestionsDetail',
        query: {
          id: data
        }
      })
    },
    askQuestionShow() {
      this.askQuestionVisible = true
    },
    submitQuestion() {
      console.log(this.checked)
      console.log(this.selectedLabel)
      console.log(this.submitTitle)
      console.log(this.askTextarea)
      if (this.selectedLabel === '') {
        this.$message.error('请选择一个标签')
        return
      }
      if (this.submitTitle === '') {
        this.$message.error('请填写标题')
        return
      }
      if (this.askTextarea === '') {
        this.$message.error('请填写内容')
        return
      }
      const obj = {
        content: this.askTextarea,
        label: this.selectedLabel,
        title: this.submitTitle,
        isAnonymous: this.checked ? 1 : 0
      }
      subQuestion(obj).then(res => {
        if (res.code === 200) {
          this.$message.success('发布成功')
          this.askQuestionVisible = false
          this.selectedLabel = ''
          this.submitTitle = ''
          this.askTextarea = ''
          this.checked = true
          this.onLoad()
        }
      })
    },
    // 回复的弹框
    replyShow(data) {
      this.currentProblemId = data
      console.log(this.currentProblemId)
      this.dialogVisible = true
    },
    // 展示大图的弹框
    showBigPicture(item) {
      this.showPicture = true
      this.currentPic = item
    },
    // 提交
    replyToSave() {
      if (this.textarea === '') {
        this.$message.error('请填写内容')
        return
      }
      const obj = {
        queryId: this.currentProblemId,
        content: this.textarea
      }
      saveReply(obj).then(res => {
        console.log(res)
        if (res.code === 200) {
          this.$message.success('回复成功')
          this.dialogVisible = false
          this.textarea = ''
          this.onLoad()
        }
      })
    },
    textRange(el) {
      console.log(el)
      const textContent = el.target
      const targetW = textContent.getBoundingClientRect().width
      const range = document.createRange()
      range.setStart(textContent, 0)
      range.setEnd(textContent, textContent.childNodes.length)
      const rangeWidth = range.getBoundingClientRect().width
      console.log(rangeWidth > targetW)
      this.showTip = rangeWidth > targetW
    },
    handleClose(done) {
      console.log(done)
      this.dialogVisible = false
    },
    askQuestionClose() {
      this.askQuestionVisible = false
      this.selectedLabel = ''
      this.submitTitle = ''
      this.askTextarea = ''
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      this.currentPage = val
      this.onLoad()
    }

  }
}
</script>

<style scoped lang="less">
/deep/ .el-dialog__title {
  color: #66b1ff;
}

.question /deep/ .el-dialog {
  min-height: 350px;
}

.resolve {
  overflow: hidden;
  margin: 20px 0;

  div {
    height: 40px;
    padding: 10px 30px;
    background: #edf1f7;
    color: #888;
    border-radius: 20px;
    margin-right: 30px;
  }

  .active {
    background: white;
    color: black;

  }
}
.questionListContent{
  //margin-top: 40px;
}

.questionMsg {
  margin-bottom: 10px;
}

.send {
  margin-top: 20px;
}

.title {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  span:nth-of-type(2) {
    flex-grow: 1;
  }
}

section {
  padding: 10px 20px 20px;
  background-color: white;
  border-radius: 20px;
  color: rgba(48, 48, 48, 1);
  text-align: left;
  margin: 20px 0;
  background: white;

  .title {
    margin-bottom: 10px;

    header {
      float: right;

    }
  }

  .time {
    color: #aaa;
    font-size: 14px;

    span {
      font-size: 14px;
      color: #aaa;
      margin-right: 20px;
    }

    span:nth-of-type(3) {
      color: #66b1ff;
    }
  }

  .teacher {
    margin-top: 40px;
  }

  p {
    margin: 20px 0;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    position: relative;

    span {
      position: absolute;
      width: 200px;
      right: 0px;
      bottom: 0px;
      color: #66b1ff;
      background: white;
    }
  }
}
.changePage{
  text-align: right;
}
/* 难题解答 开始 */
.questionContent {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
  margin: 20px 0;
  height: 60px;
  color: rgba(48, 48, 48, 1);
  font-size: 18px;
  line-height: 30px;
  position: relative;
  &::before{
    content: '';
    float: right;
    width: 0;
    height: calc(100% - 30px);
  }
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    background: #fff;
  }
  >.readTheFullArticle {
    float: right;
    color: rgba(67, 146, 253, 1);
    font-size: 18px;
    height: 30px;
    clear: both;
    margin-right: 243px;
    cursor: pointer;
  }
}
/* 难题解答 结束 */
.mainBody-image{
  display: flex;
}
//.questionEvery:hover{
//  position: relative;
//  bottom: 3px;
//}
header {
  float: right;

  span {
    font-size: 14px;
    color: #aaa;
    margin-right: 20px;
  }

  span:nth-of-type(2) {
    color: #66b1ff;
    padding: 5px;
    border: 2px solid #409EFF;
    border-radius: 6px;

  }
}
.title-c{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 700px;
}
.answer-c{
  //height: 120px;
  background-color: #f6f9fe;
  padding: 10px;
  .answer-name{
    font-family:"Microsoft YaHei";
    margin: 20px 14px 0;
    font-size: 14px;
    width: 70px;
  }
  p{
    width: 750px;
  }
}

</style>
