<template>
  <div class="index">
    <div class="box">
      <div class="menu">
        <el-row class="tac">
          <el-col>
            <el-menu
              default-active="1"
              class="el-menu-vertical-demo"
              style="border-right: none"
              @select="handleSelect"
              @open="handleOpen"
              @close="handleClose"
            >
              <el-menu-item index="1">
                <!-- <i class="el-icon-menu"></i>  -->
                <span slot="title">我的主页</span>
              </el-menu-item>
              <el-menu-item index="2">
                <!-- <i class="el-icon-menu"></i>  -->
                <span slot="title">我的提问</span>
              </el-menu-item>
              <el-menu-item index="3">
                <!--  <i class="el-icon-edit"></i>  -->
                <span slot="title">修改密码</span>
              </el-menu-item>
            </el-menu>
          </el-col>
        </el-row>
      </div>
      <div class="detail">
        <userInfo v-show="select==='1'" />
        <askQuestion v-show="select==='2'" />
        <passwordEdit v-show="select==='3'" />
      </div>
    </div>
    <indexBottom />
  </div>
</template>

<script>
import indexBottom from '../../components/indexBottom'
import userInfo from './components/userInfo'
import passwordEdit from './components/passwordEdit'
import askQuestion from './components/askQuestion'
export default {
  name: 'Index',
  components: { indexBottom, userInfo, passwordEdit, askQuestion },
  data() {
    return {
      select: '1'
    }
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath)
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath)
    },
    handleSelect(index) {
      this.select = index
    }
  }
}
</script>

<style scoped lang="less">
.el-menu-item.is-active {
  border-left: 4px solid #409EFF;
}
.index{
  .box{
    width: 80%;
    margin: 70px auto ;
    min-height: 800px;
    display: flex;
    justify-content: center;
    .menu{
      padding-top: 30px;
      width: 200px;
      //height: 286px;
      background: #FFFFFF;
      margin-right: 20px;
    }
    .detail{
      width: 980px;
      height: 100%;
      border-radius: 20px;
      //background: #FFFFFF;
      //margin-top: 100px;
    }
  }
}
</style>
