<template>
  <div class="InfoUser">
    <div class="Info"><div>基本信息</div></div>
    <div class="userInfo">
      <div class="left">
        <div class="img-box">
          <div class="img">
            <img :src="userInfo.url==null?$pic:$downloadUrl + userInfo.url">
          </div>
          <div class="btn">
            <el-upload
              class="upload-demo"
              action="api/oos/uploadFile"
              :headers="headers"
              :data="{type:6}"
              :on-success="successSubmit"
              :on-preview="handlePreview"
              :on-exceed="handleExceed"
              :before-upload="beforeUpload"
              :show-file-list="false"
            >
              <span class="BtnSpan">更换头像</span>
            </el-upload>
          </div>
        </div>
        <el-descriptions :column="6">
          <el-descriptions-item label="姓名">{{ this.$store.state.user.userInfo.name }}</el-descriptions-item>
          <el-descriptions-item label="手机号">{{ this.$store.state.user.userInfo.phone }}</el-descriptions-item>
          <!--        <el-descriptions-item label="性别">{{sys_user_sex[this.$store.state.user.userInfo.sex]}}</el-descriptions-item>-->
          <!--        <el-descriptions-item label="QQ号">{{this.$store.state.user.userInfo.qq}}</el-descriptions-item>-->
        <!--            <el-descriptions-item label="身份证号">{{this.$store.state.user.userInfo.idCard}}</el-descriptions-item>-->
        <!--            <el-descriptions-item label="生日">{{this.$store.state.user.userInfo.birthday.substr(0,11)}}</el-descriptions-item>-->
          <!--        <el-descriptions-item label="生日">{{this.$store.state.user.userInfo.birthday==null?this.$store.state.user.userInfo.birthday:this.$store.state.user.userInfo.birthday.substr(0,10)}}</el-descriptions-item>-->

          <!--        <el-descriptions-item label="学校">{{this.$store.state.user.userInfo.school}}</el-descriptions-item>-->
          <!--        <el-descriptions-item label="专业">{{this.$store.state.user.userInfo.major}}</el-descriptions-item>-->
          <!--        <el-descriptions-item label="在校班级">{{this.$store.state.user.userInfo.schoolClass}}</el-descriptions-item>-->
          <!--        &lt;!&ndash;            <el-descriptions-item label="市场来源">{{ea_market_source[this.$store.state.user.userInfo.source]}}</el-descriptions-item>&ndash;&gt;-->
          <!--        &lt;!&ndash;            <el-descriptions-item label="咨询师">{{this.$store.state.user.userInfo.referee}}</el-descriptions-item>&ndash;&gt;-->
          <!--        &lt;!&ndash;            <el-descriptions-item label="学生状态">{{ea_stydent_type[this.$store.state.user.userInfo.studentStatus]}}</el-descriptions-item>&ndash;&gt;-->
          <!--        &lt;!&ndash;            <el-descriptions-item label="审核状态">{{ea_status[this.$store.state.user.userInfo.status]}}</el-descriptions-item>&ndash;&gt;-->
          <!--        <el-descriptions-item label="所在班级">{{this.$store.state.user.userInfo.className}}</el-descriptions-item>-->
          <!--        <el-descriptions-item label="创建时间" :span="3">{{this.$store.state.user.userInfo.createTime==null?this.$store.state.user.userInfo.createTime:this.$store.state.user.userInfo.createTime.substr(0,10)}}</el-descriptions-item>-->
          <!--        &lt;!&ndash;            <el-descriptions-item label="联系地址" :span="4">{{this.$store.state.user.userInfo.address}}</el-descriptions-item>&ndash;&gt;-->
          <!--        <el-descriptions-item label="备注" :span="4">{{this.$store.state.user.userInfo.remark}}</el-descriptions-item>-->
        </el-descriptions>
        <el-descriptions :column="6">
          <el-descriptions-item label="性别">{{ sys_user_sex[this.$store.state.user.userInfo.sex] }}</el-descriptions-item>
          <el-descriptions-item label="QQ号">{{ this.$store.state.user.userInfo.qq }}</el-descriptions-item>
        </el-descriptions>
      </div>
      <div class="right">
        <el-descriptions :column="2">
          <!--          <el-descriptions-item label="姓名">{{this.$store.state.user.userInfo.name}}</el-descriptions-item>-->
          <!--          <el-descriptions-item label="手机号">{{this.$store.state.user.userInfo.phone}}</el-descriptions-item>-->
          <!--        <el-descriptions-item label="性别">{{sys_user_sex[this.$store.state.user.userInfo.sex]}}</el-descriptions-item>-->
          <!--        <el-descriptions-item label="QQ号">{{this.$store.state.user.userInfo.qq}}</el-descriptions-item>-->
          <!--                      <el-descriptions-item label="身份证号">{{this.$store.state.user.userInfo.idCard}}</el-descriptions-item>-->
          <!--                      <el-descriptions-item label="生日">{{this.$store.state.user.userInfo.birthday.substr(0,11)}}</el-descriptions-item>-->
          <!--                  <el-descriptions-item label="生日">{{this.$store.state.user.userInfo.birthday==null?this.$store.state.user.userInfo.birthday:this.$store.state.user.userInfo.birthday.substr(0,10)}}</el-descriptions-item>-->

          <el-descriptions-item label="学校">{{ this.$store.state.user.userInfo.school }}</el-descriptions-item>
          <el-descriptions-item label="专业">{{ this.$store.state.user.userInfo.major }}</el-descriptions-item>
          <!--                  <el-descriptions-item label="在校班级">{{this.$store.state.user.userInfo.schoolClass}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="市场来源">{{ea_market_source[this.$store.state.user.userInfo.source]}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="咨询师">{{this.$store.state.user.userInfo.referee}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="学生状态">{{ea_stydent_type[this.$store.state.user.userInfo.studentStatus]}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="审核状态">{{ea_status[this.$store.state.user.userInfo.status]}}</el-descriptions-item>-->
          <!--                  <el-descriptions-item label="所在班级">{{this.$store.state.user.userInfo.className}}</el-descriptions-item>-->
          <!--                  <el-descriptions-item label="创建时间" :span="3">{{this.$store.state.user.userInfo.createTime==null?this.$store.state.user.userInfo.createTime:this.$store.state.user.userInfo.createTime.substr(0,10)}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="联系地址" :span="4">{{this.$store.state.user.userInfo.address}}</el-descriptions-item>-->
          <!--                  <el-descriptions-item label="备注" :span="4">{{this.$store.state.user.userInfo.remark}}</el-descriptions-item>-->
        </el-descriptions>
        <el-descriptions :column="2">
          <!--          <el-descriptions-item label="姓名">{{this.$store.state.user.userInfo.name}}</el-descriptions-item>-->
          <!--          <el-descriptions-item label="手机号">{{this.$store.state.user.userInfo.phone}}</el-descriptions-item>-->
          <!--        <el-descriptions-item label="性别">{{sys_user_sex[this.$store.state.user.userInfo.sex]}}</el-descriptions-item>-->
          <!--        <el-descriptions-item label="QQ号">{{this.$store.state.user.userInfo.qq}}</el-descriptions-item>-->
          <!--                      <el-descriptions-item label="身份证号">{{this.$store.state.user.userInfo.idCard}}</el-descriptions-item>-->
          <el-descriptions-item label="生日">{{ this.$store.state.user.userInfo.birthday==null?this.$store.state.user.userInfo.birthday:this.$store.state.user.userInfo.birthday.substr(0,11) }}</el-descriptions-item>
          <!--                  <el-descriptions-item label="生日">{{this.$store.state.user.userInfo.birthday==null?this.$store.state.user.userInfo.birthday:this.$store.state.user.userInfo.birthday.substr(0,10)}}</el-descriptions-item>-->

          <!--          <el-descriptions-item label="学校">{{this.$store.state.user.userInfo.school}}</el-descriptions-item>-->
          <!--          <el-descriptions-item label="专业">{{this.$store.state.user.userInfo.major}}</el-descriptions-item>-->
          <!--                            <el-descriptions-item label="在校班级">{{this.$store.state.user.userInfo.schoolClass}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="市场来源">{{ea_market_source[this.$store.state.user.userInfo.source]}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="咨询师">{{this.$store.state.user.userInfo.referee}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="学生状态">{{ea_stydent_type[this.$store.state.user.userInfo.studentStatus]}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="审核状态">{{ea_status[this.$store.state.user.userInfo.status]}}</el-descriptions-item>-->
          <el-descriptions-item label="所在班级">{{ this.$store.state.user.userInfo.className }}</el-descriptions-item>
          <!--                  <el-descriptions-item label="创建时间" :span="3">{{this.$store.state.user.userInfo.createTime==null?this.$store.state.user.userInfo.createTime:this.$store.state.user.userInfo.createTime.substr(0,10)}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="联系地址" :span="4">{{this.$store.state.user.userInfo.address}}</el-descriptions-item>-->
          <!--                  <el-descriptions-item label="备注" :span="4">{{this.$store.state.user.userInfo.remark}}</el-descriptions-item>-->
        </el-descriptions>
        <el-descriptions :column="2">
          <!--          <el-descriptions-item label="姓名">{{this.$store.state.user.userInfo.name}}</el-descriptions-item>-->
          <!--          <el-descriptions-item label="手机号">{{this.$store.state.user.userInfo.phone}}</el-descriptions-item>-->
          <!--        <el-descriptions-item label="性别">{{sys_user_sex[this.$store.state.user.userInfo.sex]}}</el-descriptions-item>-->
          <!--        <el-descriptions-item label="QQ号">{{this.$store.state.user.userInfo.qq}}</el-descriptions-item>-->
          <!--                      <el-descriptions-item label="身份证号">{{this.$store.state.user.userInfo.idCard}}</el-descriptions-item>-->
          <!--          <el-descriptions-item label="生日">{{this.$store.state.user.userInfo.birthday.substr(0,11)}}</el-descriptions-item>-->
          <!--                  <el-descriptions-item label="生日">{{this.$store.state.user.userInfo.birthday==null?this.$store.state.user.userInfo.birthday:this.$store.state.user.userInfo.birthday.substr(0,10)}}</el-descriptions-item>-->

          <!--          <el-descriptions-item label="学校">{{this.$store.state.user.userInfo.school}}</el-descriptions-item>-->
          <!--          <el-descriptions-item label="专业">{{this.$store.state.user.userInfo.major}}</el-descriptions-item>-->
          <el-descriptions-item label="在校班级">{{ this.$store.state.user.userInfo.schoolClass }}</el-descriptions-item>
          <!--            <el-descriptions-item label="市场来源">{{ea_market_source[this.$store.state.user.userInfo.source]}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="咨询师">{{this.$store.state.user.userInfo.referee}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="学生状态">{{ea_stydent_type[this.$store.state.user.userInfo.studentStatus]}}</el-descriptions-item>-->
          <!--            <el-descriptions-item label="审核状态">{{ea_status[this.$store.state.user.userInfo.status]}}</el-descriptions-item>-->
          <!--                  <el-descriptions-item label="所在班级">{{this.$store.state.user.userInfo.className}}</el-descriptions-item>-->
          <el-descriptions-item label="创建时间" :span="3">{{ this.$store.state.user.userInfo.createTime==null?this.$store.state.user.userInfo.createTime:this.$store.state.user.userInfo.createTime.substr(0,10) }}</el-descriptions-item>
          <!--            <el-descriptions-item label="联系地址" :span="4">{{this.$store.state.user.userInfo.address}}</el-descriptions-item>-->
          <!--                  <el-descriptions-item label="备注" :span="4">{{this.$store.state.user.userInfo.remark}}</el-descriptions-item>-->
        </el-descriptions>
        <el-descriptions v-if="this.$store.state.user.userInfo.isTest===1" :column="2">
          <el-descriptions-item label="学生状态">试学学员</el-descriptions-item>
          <el-descriptions-item label="到期时间仅剩" :content-style="{'color': 'red','fontWright': 600}">{{ expirationTime }} <span style="color: black;font-weight: 100">天</span> </el-descriptions-item>
        </el-descriptions>
        <!--        <el-descriptions :column="2">-->
        <!--          <el-descriptions-item label="姓名">{{this.$store.state.user.userInfo.name}}</el-descriptions-item>-->
        <!--          <el-descriptions-item label="手机号">{{this.$store.state.user.userInfo.phone}}</el-descriptions-item>-->
        <!--        <el-descriptions-item label="性别">{{sys_user_sex[this.$store.state.user.userInfo.sex]}}</el-descriptions-item>-->
        <!--        <el-descriptions-item label="QQ号">{{this.$store.state.user.userInfo.qq}}</el-descriptions-item>-->
        <!--                      <el-descriptions-item label="身份证号">{{this.$store.state.user.userInfo.idCard}}</el-descriptions-item>-->
        <!--          <el-descriptions-item label="生日">{{this.$store.state.user.userInfo.birthday.substr(0,11)}}</el-descriptions-item>-->
        <!--                  <el-descriptions-item label="生日">{{this.$store.state.user.userInfo.birthday==null?this.$store.state.user.userInfo.birthday:this.$store.state.user.userInfo.birthday.substr(0,10)}}</el-descriptions-item>-->

        <!--          <el-descriptions-item label="学校">{{this.$store.state.user.userInfo.school}}</el-descriptions-item>-->
        <!--          <el-descriptions-item label="专业">{{this.$store.state.user.userInfo.major}}</el-descriptions-item>-->
        <!--          <el-descriptions-item label="在校班级">{{this.$store.state.user.userInfo.schoolClass}}</el-descriptions-item>-->
        <!--            <el-descriptions-item label="市场来源">{{ea_market_source[this.$store.state.user.userInfo.source]}}</el-descriptions-item>-->
        <!--            <el-descriptions-item label="咨询师">{{this.$store.state.user.userInfo.referee}}</el-descriptions-item>-->
        <!--            <el-descriptions-item label="学生状态">{{ea_stydent_type[this.$store.state.user.userInfo.studentStatus]}}</el-descriptions-item>-->
        <!--            <el-descriptions-item label="审核状态">{{ea_status[this.$store.state.user.userInfo.status]}}</el-descriptions-item>-->
        <!--                  <el-descriptions-item label="所在班级">{{this.$store.state.user.userInfo.className}}</el-descriptions-item>-->
        <!--                  <el-descriptions-item label="创建时间" :span="3">{{this.$store.state.user.userInfo.createTime==null?this.$store.state.user.userInfo.createTime:this.$store.state.user.userInfo.createTime.substr(0,10)}}</el-descriptions-item>-->
        <!--            <el-descriptions-item label="联系地址" :span="4">{{this.$store.state.user.userInfo.address}}</el-descriptions-item>-->
        <!--                            <el-descriptions-item label="备注" :span="4">{{this.$store.state.user.userInfo.remark}}</el-descriptions-item>-->
        <!--        </el-descriptions>-->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { studentEdit } from '../../../api/welcome'
import { getUserInfo } from '../../../api/login'
export default {
  name: 'UserInfo',
  created() {
    console.log(this.$store.state.user.userInfo)
    this.$store.state.user.userInfo.testHour
    this.expirationTime = this.$moment(this.$store.state.user.userInfo.createTime).add(this.$store.state.user.userInfo.testHour, 'day').diff(Date(), 'days')
  },
  computed: {
    ...mapState({
      sys_user_sex: state => state.dict.dictTree.sys_user_sex['obj'],
      ea_market_source: state => state.dict.dictTree.ea_market_source['obj'],
      ea_stydent_type: state => state.dict.dictTree.ea_students_status['obj'],
      ea_status: state => state.dict.dictTree.ea_audit_status['obj'],
      userInfo: state => state.user.userInfo
    })
  },
  data() {
    return {
      headers: {
        Authorization: 'Bearer ' + this.$store.state.user.token
      },
      expirationTime: 0,
      fileType: ['image', 'jpg', 'png', 'jpeg']
    }
  },
  methods: {
    handlePreview(file) {
      console.log(file)
    },
    // 上传之前
    beforeUpload(file) {
      console.log(file)
      let fileExtension
      if (file.size > 52428800) {
        this.$message.error('文件超过50M')
        return false
      }
      fileExtension = file.name.slice(file.name.lastIndexOf('.') + 1)
      console.log(fileExtension)
      if (!this.fileType.includes(fileExtension)) {
        this.$message.error('文件只能是图片格式')
        return false
      }
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`)
    },
    successSubmit(fileRes) {
      if (fileRes.code === 200) {
        const obj = {
          id: this.$store.state.user.userInfo.id,
          url: fileRes.data
        }
        studentEdit(obj).then(res => {
          if (res.code === 200) {
            this.$message.success('操作成功')
            getUserInfo().then(res => {
              if (res.code === 200) {
                console.log('个人数据', res)
                this.$store.commit('SET_INFO', res.user)
              }
            })
          }
        })
      }
    }
  }
}

</script>

<style scoped lang="less">
.InfoUser{
  position: relative;
  margin-top: 100px;
  border-radius: 20px;
  .Info{
    position: absolute;
    left: 0px;
    top: -79px;
    width: 120px;
    height: 40px;
    border-radius: 45px;
    background-color: white;
    text-align: center;
    margin: auto;
    div{
      width: 72px;
      height: 40px;
      background-color: white;
      color: rgba(0, 0, 0, 1);
      font-size: 16px;
      text-align: center;
      margin: auto;
      line-height: 40px;
    }
  }

}
/deep/.el-descriptions :not(.is-bordered) .el-descriptions-item__cell{
  padding-left: 30px;
  padding-top: 20px;
}
    .userInfo{
      //width: 50%;
      position: relative;
      bottom: 20px;
        padding: 20px;
      background-color: white;
      border-radius: 20px;
      .right{
        //width: 500px;
        padding-top: 30px;
position: absolute;
        top: 0;
        left: 400px;
      }
    }
    .img-box{
        margin-top: 25px;
        .img{
          width: 142px;
          height: 142px;
          /*border: 1px dashed #999999;*/
          img{
            width: 100%;
            height: 100%;
            border-radius: 50%;
            margin-left: 105px;
          }
        }
        .btn{
          margin-left: 105px;
          width: 150px;
          margin-top: 20px;
        }
      }
    .BtnSpan{
      color: rgba(42, 130, 228, 1);
      font-size: 14px;
    }

</style>
