<template>
  <div class="passwordEdit">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="原密码设置" name="first">
        <div v-if="activeName === 'first'" class="form">
          <el-form ref="form" :model="form" :rules="rules" label-width="80px">
            <el-form-item label="原密码" prop="password">
              <el-input v-model="form.password" autocomplete="new-password" placeholder="请输入原密码" type="password" />
            </el-form-item>
            <el-form-item label="新的密码" prop="passwordNew">
              <el-input v-model="form.passwordNew" placeholder="请输入新的密码" type="password" />
            </el-form-item>
            <el-form-item label="确认密码" prop="confirmPassword">
              <el-input v-model="form.confirmPassword" placeholder="请再次确认新密码" type="password" />
            </el-form-item>
            <el-form-item style="width: 150px">
              <el-button type="primary" @click="onSubmit">确 定</el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-tab-pane>
      <el-tab-pane label="验证码修改" name="second">
        {{$store.state.user.userId}}
        <el-form
          v-if="activeName === 'second'"
          ref="form"
          class="ruleForm-form"
          label-position="left"
          :model="form"
          :rules="rules"
          label-width="100px"
        >
          <el-form-item label="手机号" prop="phonenumber">
            <el-input v-model="form.phonenumber" :maxlength="11" placeholder="请输入手机号" clearable />
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-input
              v-model="form.code"
              :maxlength="6"
              clearable
              class="ruleForm-form-code"
              placeholder="请输入验证码"
            >
              <template #suffix>
                <div
                  :class="[
                    'ruleForm-form-code-text',
                    codeDisabled && 'ruleForm-form-code-disabled'
                  ]"
                  @click="getVerificationCode"
                >
                  {{ codeText }}
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="密码" prop="passwordNew">
            <el-input v-model="form.passwordNew" placeholder="请输入新的密码" type="password" />
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model="form.confirmPassword" placeholder="请确认新密码" type="password" />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('form')">确 定</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
import { changePassword } from '@/api/welcome'
import { getCode, getRePassword } from '@/api/login'
import {mapState} from "vuex";

const VERIFICATION_CODE = /^1[3-9]\d{9}/
export default {
  name: 'PasswordEdit',
  data() {
    const equalToPassword = (rule, value, callback) => {
      if (this.form.passwordNew !== value) {
        callback(new Error('两次输入的密码不一致'))
      } else {
        callback()
      }
    }
    return {
      activeName: 'first',
      form: {},
      codeDisabled: false,
      codeText: '获取验证码',
      rules: {
        phonenumber: [
          { required: true, message: '请输入您的手机号', trigger: 'blur' },
          { len: 11, pattern: VERIFICATION_CODE, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入您的原密码' }
        ],
        passwordNew: [
          { required: true, trigger: 'blur', message: '请输入您的新密码' },
          { min: 5, max: 20, message: '用户密码长度必须介于 5 和 20 之间', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, trigger: 'blur', message: '请再次输入您的密码' },
          { required: true, validator: equalToPassword, trigger: 'blur' }
        ],
        code: [{ required: true, trigger: 'blur', message: '请输入验证码' }]
      },
      interval: null
    }
  },
  methods: {
    handleClick() {
      this.form = {}
    },
    getVerificationCode() {
      if (this.codeDisabled) return
      this.$refs.form.validateField('phonenumber', info => {
        if (info) return
        this.codeDisabled = true
        let num = 59
        this.codeText = num + '秒后再次获取'
        getCode({ phone: this.form.phonenumber }).then(res => {
          if (res.code !== 200) {
            this.$message.error('发送失败')
          }
        })
        this.interval = setInterval(() => {
          if (num === 1) {
            clearInterval(this.interval)
            this.codeDisabled = false
            this.codeText = '获取验证码'
            return
          }
          num--
          this.codeText = num + '秒后再次获取'
        }, 1000)
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        getRePassword({ ...this.form, newPassword: this.form.passwordNew,userId:this.userInfo.id}).then(res => {
          if (res.code === 200) {
            this.form = {}
            clearInterval(this.interval)
            this.codeDisabled = false
            this.codeText = '获取验证码'
            this.$message.success(res.msg)
          } else {
            this.$message.error(res.msg)
          }
        })
      })
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          changePassword({
            id: this.$store.state.user.userInfo.id,
            password: this.form.password,
            newPassword: this.form.passwordNew
          }).then(res => {
            if (res.code === 200) {
              this.$message.success('修改成功')
              this.form = {}
            }
          })
        }
      })
    }
  },
  computed:{
    ...mapState({
      userInfo:state=>state.user.userInfo
    })
  }
}
</script>

<style scoped lang="less">
.passwordEdit {
  position: relative;
  margin-top: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 10px;

  .title {
    padding-bottom: 20px;
    width: 100px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 600;
    color: #333333;
  }

  ::v-deep.el-form {
    .el-form-item__content {
      width: 50%;
    }

    .el-input__suffix-inner {
      display: flex;
      flex-direction: row-reverse;
    }

    .ruleForm-form-code-text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #157DFB;
      padding-right: 6px;
      cursor: pointer;
    }

    .ruleForm-form-code-disabled {
      color: #999999;
    }
  }
}

</style>
